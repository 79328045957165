<template>
    <div class="page-wrapper">
        <section class="hero_centre contact">
            <div class="title_wrap">
                <img
                    src="@/assets/img/icon-deux-maisons.svg"
                    class="bigger"
                    alt="Icon Maison Péladeau"
                    data-inview="fadeInUp"
                    data-delay="200"
                />
                <h1 class="title" data-inview="fadeInUp" data-delay="300">
                    <span v-html="contentIsLoaded ? content.entete2.titre : ''"></span>
                </h1>
                <h2 class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="contentIsLoaded ? content.entete2.texte : ''"></span>
                </h2>
            </div>
        </section>

        <section class="contact_wrap" v-if="globalsIsLoaded">
            <div class="contacts">
                <div class="bloc" v-for="(item, i) in globals.centresEntry.data" :key="i">
                    <h3 class="title small">{{ item.page.titre }}</h3>
                    <a
                        :href="'https://www.google.com/maps/place/' + item.page.informationsDeContact.adresse.replace('<br />\n', ' ')"
                        target="_blank"
                        class="contact"
                    >
                        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M22.4995 22.086C19.7572 22.086 17.5328 19.9077 17.5328 17.2186C17.5328 14.5296 19.7571 12.3497 22.4995 12.3497C25.2433 12.3497 27.4677 14.5295 27.4677 17.2186C27.4677 19.9078 25.2435 22.086 22.4995 22.086V22.086ZM34.3039 13.1532C34.0846 12.545 33.8155 11.9536 33.4998 11.388C33.184 10.821 32.8232 10.2783 32.4203 9.76617C32.0175 9.25551 31.5741 8.77534 31.0951 8.33176C30.6145 7.88666 30.098 7.47813 29.5521 7.11229C29.0015 6.7434 28.4197 6.41873 27.8162 6.14128C27.2033 5.8608 26.5672 5.6291 25.9155 5.45077C25.2482 5.26632 24.5653 5.1398 23.8763 5.06968C23.0596 4.98585 22.2259 4.97822 21.4078 5.04377C20.7156 5.09864 20.0281 5.21145 19.3562 5.37913C18.6998 5.54223 18.0589 5.76021 17.4383 6.02851C16.8286 6.29222 16.2406 6.6032 15.6822 6.95987C15.1285 7.31199 14.6043 7.70833 14.1127 8.14274C13.6259 8.57414 13.1717 9.04515 12.758 9.54667C12.3442 10.0466 11.9724 10.5802 11.6427 11.1381C11.3145 11.699 11.0298 12.2844 10.795 12.8895C10.7608 12.9764 10.7281 13.0648 10.697 13.1533C9.96595 15.1776 9.77617 17.4243 10.2895 19.708C11.4965 25.0935 16.0386 29.2733 18.81 33.9225C19.0558 34.3341 21.3392 37.9239 22.1667 39.7744V39.7759C22.2196 39.907 22.3487 40 22.4996 40C22.652 40 22.7811 39.907 22.834 39.7759V39.7744C23.6615 37.9238 25.9449 34.3342 26.1906 33.9225C28.9624 29.2733 33.5043 25.0935 34.7112 19.708C35.223 17.4245 35.0347 15.1778 34.3037 13.1533"
                            />
                        </svg>
                        <address class="regular-text">
                            <span v-html="item.page.informationsDeContact.adresse"></span>
                        </address>
                    </a>
                    <a :href="'tel:' + item.page.informationsDeContact.telephone" class="contact">
                        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.6169 5H29.3831C30.8167 5 32 6.20858 32 7.67283V37.3272C32 38.7914 30.8167 40 29.3831 40H14.6169C13.1833 40 12 38.7914 12 37.3272V7.67283C12 6.20858 13.1833 5 14.6169 5V5ZM20.2242 37.5814C19.8508 37.5814 19.6018 37.3271 19.6018 36.9456C19.6018 36.5642 19.8508 36.3099 20.2242 36.3099H23.8385C24.1504 36.3099 24.4609 36.5642 24.4609 36.9456C24.4609 37.3271 24.149 37.5814 23.8385 37.5814H20.2242ZM13.7441 9.32703V33.8913H30.3172V9.32703H13.7441Z"
                            />
                        </svg>
                        <p class="regular-text">{{ item.page.informationsDeContact.telephone }}</p>
                    </a>
                    <a :href="'mailto:' + item.page.informationsDeContact.courriel" class="contact">
                        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.875 15.0002C11.5063 15.0002 11.1682 15.1096 10.8789 15.2887L21.582 24.4904C22.1472 24.9772 22.832 24.9772 23.3984 24.4904L34.1209 15.2788C33.8328 15.101 33.491 15 33.1248 15L11.875 15.0002ZM10.1074 16.2502C10.0427 16.4377 10 16.636 10 16.8464V29.1539C10 30.1767 10.8362 31 11.875 31H33.125C34.1638 31 35 30.1767 35 29.1539V16.8464C35 16.636 34.9573 16.4377 34.8926 16.2502L24.2188 25.423C23.224 26.2775 21.7555 26.2691 20.762 25.4134L10.1074 16.2502Z"
                            />
                        </svg>
                        <p class="regular-text">{{ item.page.informationsDeContact.courriel }}</p>
                    </a>
                </div>
            </div>
            <div class="map">
                <img class="ph" src="@/assets/img/map.jpg" alt="map" />
                <div id="map"></div>
            </div>
        </section>

        <section class="form_wrap">
            <h3 class="title medium">Écrivez-nous</h3>

            <form
                action=""
                class="form"
                name="contact"
                method="post"
                accept-charset="UTF-8"
                @submit="sendForm($event)"
            >
                <input type="text" placeholder="Nom et prénom *" class="input" name="name" v-model="formData.nom" required />
                <input type="email" placeholder="Courriel *" class="input" name="email" v-model="formData.courriel" required />
                <input type="text" placeholder="Téléphone *" class="input" name="telephone" v-model="formData.telephone" required />

                <div class="radio mr">
                    <p class="regular-text">Centre pour homme</p>
                    <input type="radio" name="centre" v-model="formData.centre" value="Centre pour homme" />
                </div>
                <div class="radio">
                    <p class="regular-text">Centre pour femme</p>
                    <input type="radio" name="centre" v-model="formData.centre" value="Centre pour femme" />
                </div>

                <textarea placeholder="Message *" class="input textarea" name="message" v-model="formData.message" required></textarea>

                <input type="submit" value="Envoyer" class="submit" />
            </form>

            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'Contact',

    data() {
        return {
            // teamCarousel: null,
            mapMarker: null,
            mapMarker2: null,
            isSending: false,
            formMessage: '',
            formData: {
                nom: '',
                courriel: '',
                telephone: '',
                centre: 'Centre pour homme',
                message: ''
            },
        }
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['formName'] = 'Contact'
            this.formData['sujet'] = 'Contact'

            const formData = new FormData()
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)
            formData.append('centre', this.formData.centre)
            formData.append('courriel', this.formData.courriel)
            formData.append('message', this.formData.message)
            formData.append('nom', this.formData.nom)
            formData.append('telephone', this.formData.telephone)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre message a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    // this.hideform()
                    this.formData = {}
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.hideform()
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_centre')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_centre').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_centre').classList.remove('remove_margin_top')
            }
        },
        initGoogleMap() {
            /* global google */
            const mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 11,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(46.050820, -74.287110),

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#e9e9e9' }, { lightness: 17 }]
                },
                {
                    featureType: 'landscape',
                    elementType: 'geometry',
                    stylers: [{ color: '#f5f5f5' }, { lightness: 20 }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.fill',
                    stylers: [{ color: '#ffffff' }, { lightness: 17 }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }]
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'geometry',
                    stylers: [{ color: '#ffffff' }, { lightness: 18 }]
                },
                {
                    featureType: 'road.local',
                    elementType: 'geometry',
                    stylers: [{ color: '#ffffff' }, { lightness: 16 }]
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{ color: '#f5f5f5' }, { lightness: 21 }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#dedede' }, { lightness: 21 }]
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }]
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }]
                },
                { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
                },
                {
                    featureType: 'administrative',
                    elementType: 'geometry.fill',
                    stylers: [{ color: '#fefefe' }, { lightness: 20 }]
                },
                {
                    featureType: 'administrative',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }]
                }
                ]
            }

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            const mapElement = document.getElementById('map')

            // Create the Google Map using our element and options defined above
            const map = new google.maps.Map(mapElement, mapOptions)

            // Let's also add a marker while we're at it
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(46.052320, -74.185030),
                map,
                title: 'Stonepine',
                icon: '/static-img/pin-map.svg'
            })

            this.mapMarker = marker

            const marker2 = new google.maps.Marker({
                position: new google.maps.LatLng(46.074250, -74.341310),
                map,
                title: 'Stonepine',
                icon: '/static-img/pin-map.svg'
            })

            this.mapMarker2 = marker2
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })
                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })

                        const scriptId = 'mapInitEl'
                        const mapAlreadyAttached = !!document.getElementById(scriptId)

                        if (mapAlreadyAttached) {
                            if (window.google) {
                            this.initGoogleMap()
                            }
                        } else {
                            window.mapApiInitialized = this.initGoogleMap

                            const script = document.createElement('script')
                            script.id = scriptId
                            script.src =
                            'https://maps.googleapis.com/maps/api/js?key=AIzaSyA_iUWeCzI-ONlOIDfx18r3oVDKWYWhZDU&libraries=places,geometry&callback=mapApiInitialized'
                            document.body.appendChild(script)
                        }
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped>
.map {
    position: relative;
}
.map .ph {
    opacity: 0;
    z-index: -1;
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
</style>
